<template>
  <div>
    <div class="row text-center landing">
      <div class="col mb-4" md="6">
        <div class="card leftLanding">
          <h2 class="display-2 mb-1 titleHeader">Connect Earth API Console</h2>
          <h4 class="description">
            Our API solutions connect carbon data to drive sustainable finance.
            Empower your customers to make informed choices by integrating one
            of our APIs. Sign up to access your API key.
          </h4>
        </div>
        <div class="containerPics">
          <img :src="require('../assets/mockup.png')" />
        </div>
      </div>
      <div class="col" md="6">
        <Authentication />
      </div>
    </div>
  </div>
</template>

<script>
import Authentication from './Authentication.vue'

export default {
  name: 'HelloWorld',
  components: {
    Authentication,
  },
  data: () => ({
    consoleRegion: process.env.VUE_APP_CONSOLE_REGION,
    testAPIKey: '',
    response: '',
    fullscreen: false,
    authState: undefined,
  }),
  methods: {
    toggle() {
      this.$refs['fullscreen'].toggle()
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen
    },
  },
  async created() {
      if (this.authState === 'signedin') {
        console.log('entered')
        this.$router.push('/user').catch(() => {
          console.log("Error in going to user page")
        })
      }
  },
}
</script>
<style lang="scss" scoped>
.titleHeader {
  margin-top: 40px;
  display: block;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 600;
  margin: -20px;
  font-size: 42px !important;
  font-family: Montserrat !important;
  color: white;
}

.wrapper {
  position: relative;
  height: 300px;
  .image-container {
    height: 100%;
    width: 100%;
    cursor: zoom-in;
  }
  &.fullscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    .image-container {
      height: 80%;
      width: 80%;
      cursor: zoom-out;
    }
  }
}

button.button {
  border-radius: 10px !important;
}

.card {
  background: transparent;
  border-radius: 10px !important;
  padding: 30px 20px;
}

h3,
h4 {
  text-align: center;
  color: white;
  padding-left: 20px;
}

h4 {
  font-weight: 300;
  font-size: 15px;
}

.code {
  margin: 0 auto;
  margin-top: 40px;
  display: block;
  align-items: center;
  text-align: center;
  height: 300px;
  border-radius: 10px;
}

/*BASIC*/

.containerPics {
  position: relative;
  height: 500px;
  padding-left: 30px;
  display: flex;
  justify-content: center;
}

/*PHOTO SHAPE*/
.picone,
.pictwo {
  border-radius: 10px;
  margin: 10px 20px 20px 20px;
  position: absolute;
  -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}

/*SPECIFIC PHOTOS*/
.picone {
  height: 109px;
  animation: animateImagesSlidePicOne 2s ease-in-out;
  -webkit-transform: translateX(120px) rotate(-12deg);
  -moz-transform: translateX(120px) rotate(-12deg);
  -o-transform: translateX(120px) rotate(-12deg);
  -ms-transform: translateX(120px) rotate(-12deg);
  transform: translateX(120px) rotate(-12deg);
  -webkit-transition: transform 1.05s ease-in-out;
  -moz-transition: transform 1.05s ease-in-out;
  -ms-transition: transform 1.05s ease-in-out;
}

.pictwo {
  height: 92px;
  animation: animateImagesSlidePicTwo 2s ease-in-out;
  -webkit-transform: translateX(-120px) translateY(10px) rotate(12deg);
  -moz-transform: translateX(-120px) translateY(10px) rotate(12deg);
  -o-transform: translateX(-120px) translateY(10px) rotate(12deg);
  -ms-transform: translateX(-120px) translateY(10px) rotate(12deg);
  transform: translateX(-120px) translateY(10px) rotate(12deg);
  -webkit-transition: transform 1.05s ease-in-out;
  -moz-transition: transform 1.05s ease-in-out;
  -ms-transition: transform 1.05s ease-in-out;
}

@keyframes animateImagesSlidePicOne {
  0% {
    -webkit-transform: rotate(-7deg);
    -moz-transform: rotate(-7deg);
    -o-transform: rotate(-7deg);
    -ms-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }
  100% {
  }
}

@keyframes animateImagesSlidePicTwo {
  0% {
    -webkit-transform: rotate(7deg);
    -moz-transform: rotate(7deg);
    -o-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
  }
  100% {
  }
}

.containerPics:hover > .picone {
  -webkit-transform: rotate(-7deg);
  -moz-transform: rotate(-7deg);
  -o-transform: rotate(-7deg);
  -ms-transform: rotate(-7deg);
  transform: rotate(-7deg);
}

.containerPics:hover > .pictwo {
  -webkit-transform: rotate(7deg);
  -moz-transform: rotate(7deg);
  -o-transform: rotate(7deg);
  -ms-transform: rotate(7deg);
  transform: rotate(7deg);
}

.landing {
  margin: 0 auto;
  max-width: 1200px;
}

.leftLanding {
  margin-top: 60px;
}

.card {
  box-shadow: unset !important;
  border: none !important;
}

.sub-header {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700 !important;
  font-size: 20px;
}

.description {
  line-height: 1.5;
  font-weight: 200;
}
</style>
