<script setup>
/* eslint-disable */
import { signIn, confirmSignIn, signUp } from '@aws-amplify/auth'
import {
  Authenticator,
  AuthenticatorSignUpFormFields,
  useAuthenticator,
  AmplifyCheckBox,
} from '@aws-amplify/ui-vue'
import '@aws-amplify/ui-vue/styles.css'
import { ref, toRefs } from 'vue'

const { validationErrors } = toRefs(useAuthenticator())
const mfaCode = ref('')
const currentUser = ref(null)
const errorMessage = ref('')

const services = {
  async validateCustomSignUp(formData) {
    try {
      if (/<[^>]*>/.test(formData.username)) {
        return { username: 'Username must contain a valid email' }
      }
      if (/<[^>]*>/.test(formData.password)) {
        return { password: 'Password must contain a valid string' }
      }
      if (formData.password !== formData.confirm_password) {
        return { password: 'Passwords do not match' }
      }
      if (!formData.acknowledgement) {
        return {
          acknowledgement: 'You must agree to the Terms & Conditions',
        }
      }
    } catch (e) {
      console.log(e)
    }
  },

  async handleSignUp(formData) {
    let { username, password } = formData
    let attributes = {
      email: username,
    }

    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('clientId')) {
      attributes = {
        email: username,
        'custom:userParent': urlParams.get('clientId'),
      }
    }

    return signUp({
      username,
      password,
      options: {
        userAttributes: attributes,
        autoSignIn: true, // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
      },
    })
  },

  async handleSignIn(formData) {
    try {
      const user = await signIn({
        username: formData.username,
        password: formData.password,
      })
      console.log(user)
      currentUser.value = user
      errorMessage.value = ''

      if (
        user.challengeName === 'SMS_MFA' ||
        user.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        mfaCode.value = prompt('Enter the MFA code')
        const loggedUser = await confirmSignIn(
          user,
          mfaCode.value,
          user.challengeName,
        )
        currentUser.value = loggedUser
        return loggedUser
      }
      return user
    } catch (error) {
      console.log(error)
      let errorMessage = ''
      if (error.name === 'NotAuthorizedException') {
        errorMessage = 'Incorrect username or password.'
      } else if (error.name === 'UserNotFoundException') {
        errorMessage = 'User does not exist'
      } else {
        errorMessage = 'An error occurred. Please try again.'
      }
      throw new Error(errorMessage)
    }
  },
}
</script>
<template>
  <div class="authForm">
    <authenticator
      :form-fields="formFields"
      :services="services"
      class="authenticator"
    >
      <template v-slot:sign-up-fields>
        <authenticator-sign-up-form-fields />
        <amplify-check-box :errorMessage="validationErrors.acknowledgement" />
      </template>
    </authenticator>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>
<script>
import { getCurrentUser } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'

export default {
  data() {
    return {
      clientId: this.$route.query.clientId,
      user: this.$store.getters.user || undefined,
      authState: undefined,
      authConfig: {
        signInConfig: {
          isSignUpDisplayed: false,
        },
      },
      formFields: {
        signIn: {
          username: {
            labelHidden: false,
            placeholder: 'Enter Your Email Here',
            isRequired: true,
            label: 'Sign in to your account:',
          },
        },
        confirmVerifyUser: {
          confirmation_code: {
            labelHidden: false,
            label: 'Confirmation Code',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: true,
          },
        },
        confirmSignIn: {
          confirmation_code: {
            labelHidden: false,
            label: 'Confirmation Code',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
          },
        },
        signUp: {
          username: {
            labelHidden: false,
            placeholder: 'Enter Your Email Here',
            isRequired: true,
            label: 'Sign up for a free API Key:',
          },
          password: {
            labelHidden: true,
            placeholder: 'Enter your Password Here',
            isRequired: true,
          },
          confirm_password: {
            labelHidden: true,
            placeholder: 'Confirm your Password Here',
            isRequired: true,
          },
        },
      },
    }
  },
  async created() {
    try {
      const authUser = await getCurrentUser()
      if (authUser) {
        this.$router.push('/user').catch((e) => {
          console.log('Error on signing out')
        })
      }
    } catch (e) {
      console.log('Error in user authentication')
    }

    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signedIn':
          this.$router.push('/user').catch((e) => {
            console.log('Error in going to user page')
          })
        case 'authenticated':
          this.$router.push('/user').catch((e) => {
            console.log('Error in going to user page')
          })
        case 'configured':
          this.$router.push('/user').catch((e) => {
            console.log('Error in going to user page')
          })
      }
    })
  },
}
</script>
<style>
.amplify-authenticator__tabs {
  padding: 10px 10px 10px 0px;
  margin-bottom: -100px !important;
}

[data-amplify-authenticator] [data-amplify-router] {
  height: 480px !important;
  width: 450px !important;
  border-radius: 10px !important;
}

.amplify-field__error-message {
  font-size: 12px;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 15px 40px;
}

.amplify-label {
  text-align: left;
  padding-left: 20px;
  font-size: 14px;
}

.amplify-input {
  padding: 10px;
  margin: 4px;
  border-radius: 4px !important;
  border-width: 1px !important;
  font-size: 12px;
  height: 50px;
  text-align: left;
}

.amplify-field-group__outer-end {
  height: 50px !important;
  margin-top: 5px;
}

.amplify-tabs[data-indicator-position='top'] {
  border-top: none !important;
}

.amplify-tabs-item[data-spacing='equal'] {
  border-top: none !important;
  border-radius: 10px;
  margin-top: 2px !important;
}

.amplify-tabs-item[data-state='active'] {
  border-bottom: 2px #455bad solid;
  color: #455bad !important;
  border-top: none !important;
}

.data-amplify-footer {
  background: white !important;
}

.amplify-button {
  background: #455bad !important;
  color: white !important;
}

.amplify-button--link {
  color: black !important;
  background: white !important;
  font-weight: 300;
}

.authForm {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.errorMessage {
  color: red;
  margin-top: 10px;
}
</style>
