import { createWebHistory, createRouter } from "vue-router";
import Home from '../views/Home.vue'
import PaymentSuccess from '../views/PaymentSuccess.vue'
import PaymentFailure from '../views/PaymentFailure.vue'
import CancellationSuccess from '../views/CancellationSuccess.vue'
import CancellationFailure from '../views/CancellationFailure.vue'

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/payment_success',
    name: 'Payment_success',
    component: PaymentSuccess
  },
  {
    path: '/payment_failure',
    name: 'Payment_failure',
    component: PaymentFailure
  },
  {
    path: '/cancellation_success',
    name: 'Cancellation_success',
    component: CancellationSuccess
  },
  {
    path: '/cancellation_failure',
    name: 'Cancellation_failure',
    component: CancellationFailure
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/User.vue')
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router
