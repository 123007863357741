<template>
    <div
        style="min-width: 400px; text-align: center; max-width: 600px; padding:40px; margin: auto auto; background: #495BAB; border-radius: 10px;">
        <h1 style="color:white;margin-bottom: 40px;">Payment Unsuccessful!</h1>
        <h3 style="color:white; margin-bottom: 40px;"> Unfortunately, your payment has been unsuccessful. Please return
            to the console and try again:</h3>
        <a href="./user"
            style="display: block; margin: 0 auto; padding:20px; background:#1B2036; width: 200px; text-decoration:none; color: white; border-radius: 10px;">Go
            to Console </a>
    </div>
</template>