import { createApp } from "vue";
import App from "./App.vue";
import "@aws-amplify/ui-vue";
import { Amplify } from "aws-amplify";
import { I18n } from "aws-amplify/utils";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";
import fullscreen from "vue-fullscreen";

import { loadFonts } from "./plugins/webfontloader";
import store from "./store";
import router from "./router";

loadFonts();
const DEBUG_USER_API = process.env.VUE_APP_DEBUG_USER_API;
const PROD_USER_API = process.env.VUE_APP_PROD_USER_API;

const useDebugOrProdUrl = (debugUrl, prodUrl) => {
  return process.env.VUE_APP_NODE_ENV === "DEBUG" ? debugUrl : prodUrl;
};

const userApiEndpoint = useDebugOrProdUrl(DEBUG_USER_API, PROD_USER_API);

I18n.setLanguage("improve-amplify-wording");

const dict = {
  "improve-amplify-wording": {
    "Confirm Sign up": "Check your email for the confirmation code",
  },
};

I18n.putVocabularies(dict);
I18n.setLanguage("improve-amplify-wording");

const config = {
  Auth: {
    Cognito: {
      region: process.env.VUE_APP_REGION,
      userPoolId: process.env.VUE_APP_USER_POOL_ID,
      userPoolClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: false,
      loginWith: {
        oauth: {
          scope: ["email", "openid"],
          redirectSignIn: process.env.VUE_APP_REDIRECT_SIGN_IN,
          redirectSignOut: process.env.VUE_APP_REDIRECT_SIGN_OUT,
          responseType: "code",
        },
      },
    },
  },
  API: {
    REST: {
      UserAPI: {
        endpoint: userApiEndpoint,
      },
    },
  },
};

Amplify.configure(config);

const app = createApp(App);

app.use(store);
app.use(router);
app.use(fullscreen);
app.use(VueClipboard);
app.mount("#app");
