import Vuex from 'vuex'
import { get } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

export default new Vuex.Store({
  state: {
    user: undefined,
    sandboxUser: undefined,
    alert: {
      message: '',
      type: ''
    },
  },
  
  getters: {
    getAlertObj: state => {
      if (state.alert.message && state.alert.type){
        return state.alert;
      }
      return; 
    },
    user(state){
      return state.user;
    },
    sandboxUser(state){
      return state.sandboxUser
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user.user;
      state.sandboxUser = user.sandboxUser
    },
    setAlert(state, alert){
      state.alert.message = alert.message;
      state.alert.type = alert.type;
    },
    clearAlert(state){
      state.alert.message = '';
      state.alert.type = '';
    },
  },
  
  actions: {
    async getUser({ commit }) {
      const response = get({ apiName: 'UserAPI', path: '/user', options: {
        headers: {
          Authorization: `Bearer ${(await fetchAuthSession()).tokens?.idToken?.toString()}`
        }
      } })
      const { body } = await response.response
      const user = await body.json()
      return commit('setUser', user);
    }
  }
});
