<template>
  <div class="background">
    <div class="navbar">
      <a href="./"><img src="logo.png" class="logoClass" /> </a>
      <br/>
      <div v-bind:class="shouldNotShowRegionButtons? 'displayNone': '' " >
        <div class="regionSelector">
          <a class="linkToRegionalConsole" 
                v-bind:href="USAConsoleURL" 
                v-bind:class="deploymentRegion=='US-EAST-1'? 'selectedRegion':''">
            <img class="regionFlag" :src="require('./assets/earth.png')" />
            Global Console
            </a>
          <a class="linkToRegionalConsole"
              v-if="deploymentRegion=='EU-CENTRAL-1'"
              v-bind:href="EUConsoleURL" 
              v-bind:class="deploymentRegion=='EU-CENTRAL-1'? 'selectedRegion':''">
            <img class="regionFlag" src="./assets/eu-flag.png" />
            EU Custom Enterprise</a>
        </div>
      </div>   
    </div>

    <div class="bax">
      <router-view/>
    </div>
    <footer class="footer">
      <a
      href="https://www.connect.earth/terms-and-conditions"
      target="_blank"
      class="footerLink"
      >Terms & Conditions</a>
      <a
      href="https://www.connect.earth/privacy-policy"
      target="_blank"
      class="footerLink"
      >Privacy Policy</a>
    </footer >
  </div>
</template>

<script>
export default {
  name: 'App',
   data: () => ({
    shouldNotShowRegionButtons: window.location.href.split("/")[3] === "user",
    deploymentRegion: process.env.VUE_APP_REGION,
    EUConsoleURL: process.env.VUE_APP_EU_CONSOLE_URL,
    USAConsoleURL: process.env.VUE_APP_USA_CONSOLE_URL,
   }),
   watch: {
    '$route' () {
      this.shouldNotShowRegionButtons= window.location.href.split("/")[3] === "user";
    },
  },
};
</script>
<style>
:root {
  --amplify-primary-color: #33394d;
  --amplify-primary-tint: #007bff;
  --amplify-primary-shade: #007bff;
  --amplify-border-radius: 10px;
}

.bax {
  background: #071028;
  background-repeat: repeat;
  background-size: 3vw;
}

.button__content {
  text-transform: none;
  letter-spacing: 0 !important;
}

.div {
  border-radius: 10px !important;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&display=swap');
body,
p,
div,
span,
button,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat !important;
}

.signIn {
  margin-top:10px;
  font-size: 18px;
}

.logoClass {
  font-size: 30px !important;
  font-weight: 900 !important;
  color: #162447 !important;
  width: 200px !important;
  margin-left: 20px;
  margin-top: 30px !important;
}

button.button {
  border-radius: 10px !important;
}

.div--fixed {
  box-shadow: unset !important;
}

.div.div--fixed {
  position: unset !important;
}

.div--fixed {
  margin-bottom: -60px !important;
}

.regionFlag {
  height: 25px;
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5px;
}

.linkToRegionalConsole {
  font-size: 9px;
  color: white !important;
  text-decoration: none !important;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  margin-left: 20px;
  margin-top: 18px;
  width:65px;
  padding-top: 8px;
  padding-bottom: 3px;
  border-radius: 10px;
  }

.selectedRegion {
    background: #455BAD !important;
}

.displayNone{
  display: none !important;
}

.regionSelector {
  display:flex;
  list-style-type:none;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: -20px;
  float: right;
  width: 220px;
  margin: 0 auto;
  text-align: center;
}
.regionSelector > a {
  display: list-item;
}

.footer {
  padding-right: 80px !important;
}

.footerLink {
  color: white !important;
  padding: 20px 20px 40px 20px;
  float: right;
}

.background {
  background: #071028;
}
</style>
